.footer-section {
  background-color: #1b2433;
}
.footer-container {
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
  color: white;
  border-bottom: 2px solid #293241;
  border-radius: 2px;
}
/* Title */
.ft-title {
  color: #1a8efd;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0.8px;
}
.ft-sign {
  color: #1ecab0;
  font-family: Cambria, sans-serif;
  font-size: 40px;
  font-weight: bold;
}
.ft-description {
  width: 420px;
  margin: 16px 0 40px;
  color: #d0d3da;
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 1.7rem;
}
.ft-input-title {
  color: white;
  font-family: "Rubik", sans-serif;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.8px;
}
.ft-input {
  width: 300px;
  margin: 20px 8px 0 0;
  padding: 16px 22px;
  color: white;
  background-color: #293241;
  border: 1px solid transparent;
  border-radius: 50px;
  outline: transparent;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  letter-spacing: 0.8px;
}
.ft-btn {
  padding: 14px 18px;
  color: white;
  border: 2px solid transparent;
  border-radius: 50px;
  outline: transparent;
  background-color: #1a8efd;
  font-size: 18px;
  font-family: "Rubik", sans-serif;
  letter-spacing: 0.8px;
  cursor: pointer;
  transition: all 0.4s ease;
}
.ft-btn:hover {
  color: #1a8efd;
  background-color: white;
  border: 2px solid #1a8efd;
}
/* Lists */
.ft-list-title {
  margin: 16px 0;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.8px;
}
.ft-list-items {
  list-style-type: none;
  padding-left: 0px;
}
.ft-list-items li a {
  text-decoration: none;
  color: #a5a7ac;
  padding: 0 0 2px 0;
  border-bottom: 2px dotted transparent;
  transition: border 0.2s ease;
}
.ft-list-items li {
  margin: 24px 0;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.8px;
}
.ft-list-items li a:hover {
  border-bottom: 2px dotted #a5a7ac;
}
/* CopyRight */
.ft-copyright {
  padding: 24px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #cbcdd3;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.8px;
}
.ft-social-links {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.ft-social-links li a {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #cbcdd3;
  border-radius: 50%;
  cursor: pointer;
}
.ft-social-links li a svg {
  width: 24px;
  height: 24px;
  padding: 1px;
}
.ft-social-links li a svg path {
  fill: #cbcdd3;
}
.ft-social-links li a:hover,
.ft-social-links li a:hover svg path {
  fill: #1a8efd;
  border: 2px solid #1a8efd;
}
/* Responsive */
@media screen and (max-width: 700px) {
  .footer-container {
    padding: 24px;
  }
  .ft-description {
    width: 100%;
    margin: 16px 0 24px;
  }
  .ft-input {
    width: 100%;
    margin: 16px 0;
  }
  .ft-btn {
    width: 100%;
    margin: 0 0 16px;
  }
  .ft-copyright {
    padding: 18px;
    display: block;
    text-align: center;
  }
  .ft-social-links {
    margin: 16px 0 0;
  }
}
