.fadeMe {
  position: fixed;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(221, 216, 216, 0.8);
}

.spinner {
  margin: auto;
  margin-top: 200px;
  z-index: 999;
}

@media only screen and (max-device-width: 600px) {
  .song_table {
    font-size: 20px;
  }
}

.clickAbleLink {
  color: rgb(118, 118, 240);
  text-decoration: underline;
  cursor: pointer;
}
.loader-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Adjust height as needed */
}

.loader {
  /* Your loader styles remain unchanged */
  width: 48px;
  height: 48px;
  border-width: 3px;
  border-style: dashed solid solid dotted;
  border-color: #ff3d00 #ff3d00 transparent #ff3d00;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after {
  /* Your loader ::after styles remain unchanged */
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 20px;
  top: 31px;
  border: 10px solid transparent;
  border-right-color: #ff3d00;
  transform: rotate(-40deg);
}

@keyframes rotation {
  /* Your rotation keyframes remain unchanged */
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.message {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

.message .message-header {
  display: flex;
  justify-content: space-between;
}

.message .author {
  font-weight: bold;
}

.message .time {
  font-size: smaller;
}

.message .message-body {
  margin-top: 10px;
}

.notification-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  position: relative;
  margin-left: 4px;
  top: -5px;
}
