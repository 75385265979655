@import url(https://rsms.me/inter/inter.css);
.fadeMe {
  position: fixed;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(221, 216, 216, 0.8);
}

.spinner {
  margin: auto;
  margin-top: 200px;
  z-index: 999;
}

@media only screen and (max-device-width: 600px) {
  .song_table {
    font-size: 20px;
  }
}

.clickAbleLink {
  color: rgb(118, 118, 240);
  text-decoration: underline;
  cursor: pointer;
}
.loader-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Adjust height as needed */
}

.loader {
  /* Your loader styles remain unchanged */
  width: 48px;
  height: 48px;
  border-width: 3px;
  border-style: dashed solid solid dotted;
  border-color: #ff3d00 #ff3d00 transparent #ff3d00;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  -webkit-animation: rotation 1s linear infinite;
          animation: rotation 1s linear infinite;
}

.loader::after {
  /* Your loader ::after styles remain unchanged */
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 20px;
  top: 31px;
  border: 10px solid transparent;
  border-right-color: #ff3d00;
  -webkit-transform: rotate(-40deg);
          transform: rotate(-40deg);
}

@-webkit-keyframes rotation {
  /* Your rotation keyframes remain unchanged */
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotation {
  /* Your rotation keyframes remain unchanged */
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.message {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

.message .message-header {
  display: flex;
  justify-content: space-between;
}

.message .author {
  font-weight: bold;
}

.message .time {
  font-size: smaller;
}

.message .message-body {
  margin-top: 10px;
}

.notification-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  position: relative;
  margin-left: 4px;
  top: -5px;
}

/**
 * Main wrapper
 */
.select-search-container {
  --select-search-background: #fff;
  --select-search-border: #dce0e8;
  --select-search-selected: #1e66f5;
  --select-search-text: #000;
  --select-search-subtle-text: #6c6f85;
  --select-search-inverted-text: var(--select-search-background);
  --select-search-highlight: #eff1f5;
  --select-search-font: "Inter", sans-serif;

  width: 300px;
  position: relative;
  font-family: var(--select-search-font);
  color: var(--select-search-text);
  box-sizing: border-box;
}

@supports (font-variation-settings: normal) {
  .select-search-container {
    --select-search-font: "Inter var", sans-serif;
  }
}

/* @media (prefers-color-scheme: dark) {
  .select-search-container {
    --select-search-background: #000;
    --select-search-border: #313244;
    --select-search-selected: #89b4fa;
    --select-search-text: #fff;
    --select-search-subtle-text: #a6adc8;
    --select-search-highlight: #1e1e2e;
  }
} */

body.is-dark-mode .select-search-container {
  --select-search-background: #000;
  --select-search-border: #313244;
  --select-search-selected: #89b4fa;
  --select-search-text: #fff;
  --select-search-subtle-text: #a6adc8;
  --select-search-highlight: #1e1e2e;
}

body.is-light-mode .select-search-container {
  --select-search-background: #fff;
  --select-search-border: #dce0e8;
  --select-search-selected: #1e66f5;
  --select-search-text: #000;
  --select-search-subtle-text: #6c6f85;
  --select-search-highlight: #eff1f5;
}

.select-search-container *,
.select-search-container *::after,
.select-search-container *::before {
  box-sizing: inherit;
}

.select-search-input {
  position: relative;
  z-index: 1;
  display: block;
  height: 48px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: var(--select-search-background);
  border: 2px solid var(--select-search-border);
  color: var(--select-search-text);
  border-radius: 3px;
  outline: none;
  font-family: var(--select-search-font);
  font-size: 16px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 48px;
  letter-spacing: 0.01rem;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
}

.select-search-is-multiple .select-search-input {
  margin-bottom: -2px;
}

.select-search-is-multiple .select-search-input {
  border-radius: 3px 3px 0 0;
}

.select-search-input::-webkit-search-decoration,
.select-search-input::-webkit-search-cancel-button,
.select-search-input::-webkit-search-results-button,
.select-search-input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search-input[readonly] {
  cursor: pointer;
}

.select-search-is-disabled .select-search-input {
  cursor: not-allowed;
}

.select-search-container:not(.select-search-is-disabled).select-search-has-focus .select-search-input,
.select-search-container:not(.select-search-is-disabled) .select-search-input:hover {
  border-color: var(--select-search-selected);
}

.select-search-select {
  background: var(--select-search-background);
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border: 2px solid var(--select-search-border);
  overflow: auto;
  max-height: 360px;
}

.select-search-container:not(.select-search-is-multiple) .select-search-select {
  position: absolute;
  z-index: 2;
  top: 58px;
  right: 0;
  left: 0;
  border-radius: 3px;
  display: none;
}

.select-search-container:not(.select-search-is-multiple).select-search-has-focus .select-search-select {
  display: block;
}

.select-search-has-focus .select-search-select {
  border-color: var(--select-search-selected);
}

.select-search-options {
  list-style: none;
}

.select-search-option,
.select-search-not-found {
  display: block;
  height: 42px;
  width: 100%;
  padding: 0 16px;
  background: var(--select-search-background);
  border: none;
  outline: none;
  font-family: var(--select-search-font);
  color: var(--select-search-text);
  font-size: 16px;
  text-align: left;
  letter-spacing: 0.01rem;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
}

.select-search-option:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: transparent !important;
}

.select-search-is-highlighted,
.select-search-option:not(.select-search-is-selected):hover {
  background: var(--select-search-highlight);
}

.select-search-is-selected {
  font-weight: bold;
  color: var(--select-search-selected);
}

.select-search-group-header {
  font-size: 12px;
  text-transform: uppercase;
  background: var(--select-search-border);
  color: var(--select-search-subtle-text);
  letter-spacing: 0.1rem;
  padding: 10px 16px;
}

.select-search-row:not(:first-child) .select-search-group-header {
  margin-top: 10px;
}

.select-search-row:not(:last-child) .select-search-group-header {
  margin-bottom: 10px;
}

.legal-text-content{
    padding: 12px 32px;
    background: linear-gradient(to right, #ECF2FF, #FBFCFF);
}
/* Title */
.legal-siteTitle a {
    text-decoration: none;
    color: #1A8EFD;
    font-size: 48px;
    letter-spacing: .8px;
}
.legal-siteTitle {
    padding: 0 0 8px 0;
    border-radius: 2px;
    background-color: white;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}
.legal-siteSign{
    color: #54de54;
    font-family: Cambria, sans-serif;
    font-size: 56px;
    font-weight: bold;
}
/* Info */
.legal-title {
    margin: 16px 0;
    padding: 0 0 2px 0;
    border-bottom: 2px solid grey;
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: .8px;
}
.legal-description {
    margin: 0 0 16px 0;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    letter-spacing: .8px;
    line-height: 1.8rem;
}
/* Footer */
.legal-footer {
    padding: 24px 40px;
    display: block;
    color: black;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: .8px;
    line-height: 1.6rem;
}
/* Responsive */
@media screen and (max-width: 600px) {
    .legal-section {
        padding: 24px;
    }
    .legal-title {
        font-size: 24px;
    }
    .legeal-description {
        font-size: 16px;
    }
    .legal-footer {
        padding: 12px 20px;
        text-align: center;
        font-size: 16px;
    }
}

.about-section {
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 24px;
  gap: 24px;
  background: linear-gradient(to right, #ecf2ff, #fbfcff);
}
/* Image */
.about-image-content {
  width: 50%;
  max-width: 100%;
  text-align: center;
}
.about-image1 {
  width: 80%;
  height: auto;
}
/* Text */
.about-text-content {
  width: 50%;
}
.about-title {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: bold;
}
.about-title > span {
  position: relative;
}
.about-title span::before {
  content: "";
  position: absolute;
  width: 75%;
  height: 4px;
  background-color: #9d2553;
  border-radius: 8px;
  left: 0;
  bottom: -12px;
}
.about-description {
  margin: 32px 0;
  color: #4d4b4b;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  letter-spacing: 0.7px;
  line-height: 1.7rem;
}
.about-text-title {
  margin: 0 0 24px 0;
  color: #4d4b4b;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.7px;
}
/* Steps */
.about-text-step {
  margin: 16px 0;
}
.about-text-sTitle {
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.7px;
}
.about-text-description {
  margin: 8px 24px;
  color: #4d4b4b;
  font-family: "Rubik", sans-serif;
  letter-spacing: 0.7px;
  line-height: 1.6rem;
}
.fa-icon {
  color: #9d2553;
}
/* Responsive */
@media screen and (max-width: 1000px) {
  .about-section {
    display: block;
  }
  .about-image-content {
    width: 100%;
    max-width: 100%;
    margin: 0 0 32px 0;
  }
  .about-image1 {
    width: 100%;
    height: 100%;
  }
  .about-text-content {
    width: 100%;
  }
}

.footer-section {
  background-color: #1b2433;
}
.footer-container {
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  grid-gap: 12px;
  gap: 12px;
  color: white;
  border-bottom: 2px solid #293241;
  border-radius: 2px;
}
/* Title */
.ft-title {
  color: #1a8efd;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0.8px;
}
.ft-sign {
  color: #1ecab0;
  font-family: Cambria, sans-serif;
  font-size: 40px;
  font-weight: bold;
}
.ft-description {
  width: 420px;
  margin: 16px 0 40px;
  color: #d0d3da;
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 1.7rem;
}
.ft-input-title {
  color: white;
  font-family: "Rubik", sans-serif;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.8px;
}
.ft-input {
  width: 300px;
  margin: 20px 8px 0 0;
  padding: 16px 22px;
  color: white;
  background-color: #293241;
  border: 1px solid transparent;
  border-radius: 50px;
  outline: transparent;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  letter-spacing: 0.8px;
}
.ft-btn {
  padding: 14px 18px;
  color: white;
  border: 2px solid transparent;
  border-radius: 50px;
  outline: transparent;
  background-color: #1a8efd;
  font-size: 18px;
  font-family: "Rubik", sans-serif;
  letter-spacing: 0.8px;
  cursor: pointer;
  transition: all 0.4s ease;
}
.ft-btn:hover {
  color: #1a8efd;
  background-color: white;
  border: 2px solid #1a8efd;
}
/* Lists */
.ft-list-title {
  margin: 16px 0;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.8px;
}
.ft-list-items {
  list-style-type: none;
  padding-left: 0px;
}
.ft-list-items li a {
  text-decoration: none;
  color: #a5a7ac;
  padding: 0 0 2px 0;
  border-bottom: 2px dotted transparent;
  transition: border 0.2s ease;
}
.ft-list-items li {
  margin: 24px 0;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.8px;
}
.ft-list-items li a:hover {
  border-bottom: 2px dotted #a5a7ac;
}
/* CopyRight */
.ft-copyright {
  padding: 24px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #cbcdd3;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.8px;
}
.ft-social-links {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
}
.ft-social-links li a {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #cbcdd3;
  border-radius: 50%;
  cursor: pointer;
}
.ft-social-links li a svg {
  width: 24px;
  height: 24px;
  padding: 1px;
}
.ft-social-links li a svg path {
  fill: #cbcdd3;
}
.ft-social-links li a:hover,
.ft-social-links li a:hover svg path {
  fill: #1a8efd;
  border: 2px solid #1a8efd;
}
/* Responsive */
@media screen and (max-width: 700px) {
  .footer-container {
    padding: 24px;
  }
  .ft-description {
    width: 100%;
    margin: 16px 0 24px;
  }
  .ft-input {
    width: 100%;
    margin: 16px 0;
  }
  .ft-btn {
    width: 100%;
    margin: 0 0 16px;
  }
  .ft-copyright {
    padding: 18px;
    display: block;
    text-align: center;
  }
  .ft-social-links {
    margin: 16px 0 0;
  }
}

.hero-section {
    padding: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 24px;
    gap: 24px;
    background: linear-gradient(to right, #ECF2FF, #FBFCFF);
}
/* Text section */
.text-section {
    width: 60%;
    padding: 0 32px;
}
.text-headline {
    margin-bottom: 12px;
    color: rgb(77, 75, 75);
    font-family: 'Rubik', sans-serif;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: .7px;
}
.text-title {
    width: 500px;
    color: black;
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: bold;
}
.text-descritpion {
    width: 475px;
    margin: 32px 0;
    color: rgb(77, 75, 75);
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    letter-spacing: .7px;
    line-height: 1.6rem;
}
.text-appointment-btn {
    padding: 14px 20px;
    color: white;
    border: 1px solid transparent;
    border-radius: 28px;
    outline: transparent;
    background-color: #1A8EFD;
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
    letter-spacing: .8px;
    cursor: pointer;
    transition: all .4s ease;
}
.text-appointment-btn:hover {
    color: #1A8EFD;
    background-color: transparent;
    border: 1px solid #1A8EFD;
}
.text-stats {
    margin-top: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 48px;
    gap: 48px;
}
.text-stats-container {
    text-align: center;
}
.text-stats-container > p {
    color: rgb(77, 75, 75);
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    letter-spacing: .7px;
}
.text-stats-container p:first-child {
    margin-bottom: 8px;
    color: #1A8EFD;
    font-family: 'Rubik', sans-serif;
    font-size: 32px;
    font-weight: bold;
}
/* Image section */
.hero-image-section {
    width: 40%;
    max-width: 100%;
    text-align: center;
}
.hero-image1 {
    width: 100%;
    height: auto;
}
/* Scroll Btn */
.scroll-up {
    width: 45px;
    height: 45px;
    display: none;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: white;
    border: 3px solid white;
    border-radius: 50%;
    background-color: #1A8EFD;
    position: fixed;
    bottom: 50px;
    right: 50px;
    font-size: 24px;
    z-index: 20;
    cursor: pointer;
}  
.show-scroll {
  display: flex;
}
/* Responsive */
@media screen and (max-width: 900px) {
    .hero-image-section {
        display: none;
    }
    .text-section {
        width: 100%;
        padding: 0;
    }
    .text-title, .text-descritpion {
        width: 100%;
    }
}
@media screen and (max-width: 600px) {
    .text-headline {
        font-size: 20px;
    }
    .text-title {
        font-size: 28px;
    }
    .text-descritpion {
        font-size: 16px;
    }
    .text-appointment-btn {
        font-size: 16px;
    }
    .text-stats {
        grid-gap: 18px;
        gap: 18px;
    }
    .text-stats-container > p {
        font-size: 16px;
    }
    .text-stats-container p:first-child {
        font-size: 22px;
    }
    .scroll-up {
        bottom: 32px;
        right: 32px;
    }
}

.info-section {
    padding: 0 32px;
    text-align: center;
    background-color: white;
}
.info-title-content {
    margin: 64px 0 128px 0;
}
.info-title {
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: bold;
}
.info-title > span {
    position: relative;
}
.info-title span::before {
    content: '';
    position: absolute;
    width: 75%;
    height: 4px;
    background-color: #9D2553;
    border-radius: 8px;
    left: 0;
    bottom: -12px;
}
.info-description {
    margin: 64px;
    text-align: center;
    color: rgb(77, 75, 75);
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    letter-spacing: .8px;
    line-height: 1.8rem;
}
/* Cards */
.info-cards-content {
    margin: 64px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 32px;
    gap: 32px;
}
.info-cards {
    height: auto;
    border: 2px solid #d7d3d3;
    border-radius: 12px;
    background-color: white;
    position: relative;
}
.info-card-title {
    margin: 32px 0 40px 0;
    color: black;
    font-family: 'Poppins', sans-serif;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: .8px;
}
.info-card-description {
    margin: 24px;
    color: rgb(77, 75, 75);
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    letter-spacing: .8px;
    line-height: 1.5rem;
}
.info-card-icon {
    position: absolute;
    top: -24px;
    left: 44%;
    padding: 10px;
    background-color: #DCE6FF;
    border-radius: 30px;
}
.info-fa-icon {
    color: #2C96FF;
    font-size: 24px;
}
/* Responsive */
@media screen and (max-width: 1000px) {
    .info-description {
        margin: 64px 32px;
    }
    .info-cards-content {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 700px) {
    .info-description {
        margin: 64px 8px;
    }
    .info-cards-content {
        grid-template-columns: repeat(1, 1fr);
    }
}

